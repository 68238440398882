import { useEffect } from "react";


const MetaPixel = () => {
    useEffect(() => {
      const hostname = window.location.hostname;
  
      if (hostname.includes('invoicing')) {
        !function(f,b,e,v,n,t,s){
            if(f.fbq) return;
            n=f.fbq=function(){
                n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
            };
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
        }(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
        // eslint-disable-next-line no-undef
        fbq('init', '894066796146226');
        // eslint-disable-next-line no-undef
        fbq('track', 'PageView');
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `
          <img height="1" width="1" style="display:none" 
          src="https://www.facebook.com/tr?id=894066796146226&ev=PageView&noscript=1" />
        `;
        document.body.appendChild(noscript);
      } else if (hostname === 'pay') {
        !function(f,b,e,v,n,t,s){
            if(f.fbq)return;
            n=f.fbq=function(){
                n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
            };
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
        }(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
        // eslint-disable-next-line no-undef
        fbq('init', '4165367503786289');
        // eslint-disable-next-line no-undef
        fbq('track', 'PageView');
        const noscript = document.createElement('noscript');
        noscript.innerHTML = `
            <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=4165367503786289&ev=PageView&noscript=1"/>
        `;
        document.body.appendChild(noscript);
      }
    }, []);
  
    return null;
  };
  
  export default MetaPixel;

